import React from "react"

export default () => {
  return (
    <div>
      <h2>Show Your Support</h2>
      <p>
        <strong>
          <a target="_blank" rel="payment noreferrer" title="★ Support Speaking of Products on Patreon ★" href="https://www.patreon.com/speakingofproducts">★ Support Speaking of Products on Patreon ★</a>
        </strong>
      </p>
      {/* <h4>Let's Get a Podcast Editor 🎙</h4>
      <p>
        If you like Speaking of Products, please consider helping Dan reach his goal of hiring a professional to edit the Speaking of Products podcast each week.
      </p> */}
      {/* <a 
        href="https://www.patreon.com/speakingofproducts" 
        rel="payment"
        className="mt-3 mb-12 md:mb-0 inline-block py-3 px-8 text-white bg-indigo-900 hover:bg-indigo-600 rounded-lg shadow"
        >
          Become a Patron!
      </a> */}
    </div>
  )
}