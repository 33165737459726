import React from "react"
import Patreon from "./patreon"

export default () => (
  <>
    <h2>Join in With the Show</h2>
    <ul>
      <li>If you have a question, feedback or suggestions for a topic, I would love to hear from you.</li>
      <li>I also welcome short snippets about your own product journey which we might go through on the show.</li>
      <li>Get in touch via Twitter: <a href="https://twitter.com/SpeakOfProducts">@SpeakOfProducts</a> or <a href="https://twitter.com/mrdanmiller">@mrdanmiller</a>.</li>
    </ul>

    <h2>Subscribe</h2>

    <ul>
      <li>Subscribe to the show on your favourite podcast player, or wherever you get your podcasts, by searching for <em>Speaking of Products</em>.</li>
    </ul>

    <h2>Please Give a Rating</h2>

    <ul>
      <li>Ratings help other people find out about the show.</li>
      <li>If you enjoy the show, please consider giving us a rating on <a href="https://podcasts.apple.com/au/podcast/speaking-of-products/id1499282040">Apple Podcasts</a>, <a href="https://www.stitcher.com/podcast/miller-productions/speaking-of-products">Stitcher</a> or <a href="https://open.spotify.com/show/0TwGcQrrDNGqVrzYZX3jZt">Spotify</a>.</li>
    </ul>

    <h2>Credits</h2>
    <ul>
      <li>Speaking of Products is a creation of <a href="https://miller.productions/">Miller Productions</a>.</li>
      <li>I'm Dan Miller and you are a fantastic product maker. All the best with whatever you are working on, and...</li>
      <li>Remember to keep Speaking of Products.</li>
      <li>Thanks for listening, I'll catch you next time.</li>
    </ul>
    <Patreon />
  </>
)
